import base64 from 'base-64'
import { navigate } from 'gatsby'
import moment from 'moment'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import utf8 from 'utf8'

import { Loader } from '@cuidardigital/commons/components/Loader'
import {
	deleteNewPatient,
	getTokenPassword,
	getUserEmail,
	getUserName,
	logout,
	newPatient
} from '@cuidardigital/commons/services/auth'
import apiNewPatient from '@cuidardigital/commons/services/patient.service'
import { formatCPF, formatDate, formatPhone } from '@cuidardigital/commons/utils/masks'

import isValidCPF from '@cuidardigital/commons/utils/cpf'
import { validateDateBirthday } from '@cuidardigital/commons/utils/date'
import { isPhoneValid } from '@cuidardigital/commons/utils/phone'
import Checkbox from '@cuidardigital/commons/components/Checkbox'
import logo from '../../assets/img/logo.png'
import {
	ComponentWrapper,
	ConsentText,
	ContainerCheckbox,
	FlexFieldWrapper,
	Form,
	FormFieldWrapper,
	FormInput,
	FormLabel,
	FormWrapper,
	InputError,
	Logo,
	SubmitButton,
	WelcomeText
} from './styles'
import MessageModal from '../Modal'
import { NEW_PATIENT } from '../../routes'

interface IObjectLiteral {
	[key: string]: any
}

interface IProps {
	location: IObjectLiteral
}

const formInitialState = {
	cpf: '',
	phone: '',
	birthDate: '',
	name: '',
	cardNumber: '',
	agreeConsent: false,
	accessToken: '',
	email: '',
	agreeUse: false
}

const errorsInitialState = {
	...Object.keys(formInitialState).reduce((value, item) => ({ ...value, [item]: false }), {})
}

const PatientRegister: React.FC<IProps> = ({ location }) => {
	const [formValues, setFormValues] = useState(formInitialState)
	const [errors, setErrors] = useState(errorsInitialState)
	const [formIsWrong, setFormIsWrong] = useState(true)

	const [loading, setLoading] = useState(true)
	const [showMessage, setShowMessage] = useState(false)
	const [messageToShow, setMessageToShow] = useState()

	useEffect(() => {
		setTimeout(() => setLoading(false), 500)
	}, [])

	useEffect(() => {
		normalizerErrors()
	}, [errors])

	const qsValues = queryString.parse(location.search)
	if (qsValues && qsValues.token) {
		try {
			newPatient({ ...qsValues })
			navigate(NEW_PATIENT)
		} catch {
			logout()
		}
	} else if (qsValues && qsValues.param) {
		try {
			const bytes = base64.decode(qsValues.param)
			const params = JSON.parse(utf8.decode(bytes))
			// Informe no GA que a página foi acessada via SMS
			if (qsValues.sms) {
				ReactGA.event({
					category: 'SMS',
					action: 'Paciente Cadastro',
					label: `${params.name} cadastrando. ${params.email}`
				})
			}
			newPatient({ ...params })
			navigate(NEW_PATIENT)
		} catch {
			logout()
		}
	} else if (!formValues.accessToken && getTokenPassword()) {
		setFormValues({
			...formValues,
			accessToken: getTokenPassword(),
			email: getUserEmail(),
			name: getUserName()
		})
	}

	const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target
		const { name } = formValues
		if (name !== value) {
			setFormValues({ ...formValues, name: value })
			setErrors({ ...errors, name: value.length === 0 })
		}
	}

	const handleAgreeConsent = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { checked } = e.target
		setFormValues({ ...formValues, agreeConsent: checked })
		setErrors({ ...errors, agreeConsent: !checked })
	}

	const handleAgreeUse = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { checked } = e.target
		setFormValues({ ...formValues, agreeUse: checked })
		setErrors({ ...errors, agreeUse: !checked })
	}

	const handleClearForm = () => {
		setFormValues(formInitialState)
		setErrors(errorsInitialState)
	}

	const handleCardNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target
		const cardNumber = value.replace(/\D/g, '')
		setFormValues({ ...formValues, cardNumber })
		setErrors({ ...errors, cardNumber: cardNumber.length === 0 })
	}

	const handlePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target
		const { phone } = formValues
		const fPhone = formatPhone(value)
		if (phone !== fPhone) {
			setFormValues({ ...formValues, phone: fPhone })
			setErrors({ ...errors, phone: !isPhoneValid(fPhone) })
		}
	}

	const handleCpf = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target
		const { cpf } = formValues
		if (cpf !== value) {
			const newCpf = formatCPF(value)
			setFormValues({ ...formValues, cpf: newCpf })
			setErrors({
				...errors,
				cpf: !isValidCPF(
					value
						.replace('.', '')
						.replace('.', '')
						.replace('-', '')
				)
			})
		}
	}

	const handleBirthdate = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target
		const { birthDate } = formValues
		const fDate = formatDate(value)
		if (birthDate !== fDate) {
			setFormValues({ ...formValues, birthDate: fDate })
			setErrors({ ...errors, birthDate: !validateDateBirthday(fDate) })
		}
	}

	const normalizerErrors = () => {
		const wrong = Object.values(errors).some(b => b) || Object.values(formValues).some(v => !v)
		setFormIsWrong(wrong)
	}

	const submitForm = () => {
		setLoading(true)
		try {
			const { name, cpf, birthDate, phone, email, accessToken, cardNumber } = formValues
			apiNewPatient({ name, cpf, birthDate, phone, email, accessToken, uf: '', cardNumber })
				.then((response: any) => {
					if (response) {
						if (response.status <= 207) {
							setMessageToShow({
								buttonText: 'Sair',
								noAction: true,
								title: 'Olá! Que bom ter você aqui',
								message:
									'Agendamento confirmado!/n Vamos enviar um e-mail com um link de acesso à consulta. /n/nAgora que seu cadastro foi concluído, retorne à área logada da Novamed, clique em "Meu Painel". /n/n Na consulta de telemedicina selecione a opção "Ver mais" no menu Detalhes, e clique no link disponibilizado para realizar o acesso à sala de atendimento online na data e hora marcada.',
								onClose: () => console.log('Agora é por e-mail'),
								withButton: false
							})
							deleteNewPatient()
							handleClearForm()
							// setReturnOnExit(true)
						} else {
							setMessageToShow({
								title: 'Pedimos desculpas',
								message: response.data.message,
								onClose: setShowMessage
							})
						}
					} else {
						setMessageToShow({
							title: 'Pedimos desculpas',
							message: 'Algo inesperado aconteceu',
							onClose: setShowMessage
						})
					}
				})
				.catch(e => {
					setMessageToShow({
						title: 'Pedimos desculpas',
						message: `Não foi possível realizar sua solicitação. ${JSON.stringify(e)}`,
						onClose: setShowMessage
					})
				})
				.finally(() => {
					setShowMessage(true)
					setLoading(false)
				})
		} catch (error) {
			setLoading(false)
			setMessageToShow({
				title: 'Pedimos desculpas',
				message: `Houve um problema no fluxo de login. ${JSON.stringify(error)}`,
				onClose: setShowMessage
			})
			setShowMessage(true)
		}
	}

	if (loading) {
		return <Loader />
	}

	return (
		<>
			{/* {loading && <Loader />} */}
			{showMessage && <MessageModal {...messageToShow} />}
			<ComponentWrapper>
				<FormWrapper>
					<Logo src={logo} />
					<WelcomeText fontSize='16px/32px'>
						Olá! <br />
						Confirme seu agendamento
					</WelcomeText>
					<Form>
						<FormFieldWrapper>
							<FormLabel>Nome completo</FormLabel>
							<FormInput
								type='text'
								id='name'
								name='name'
								value={formValues.name}
								error={errors.name}
								onChange={handleName}
								placeholder='Digite aqui seu nome completo'
							/>
							{errors.name && <InputError fontSize='12px/18px'>Nome é obrigatório</InputError>}
						</FormFieldWrapper>
						<FormFieldWrapper>
							<FormLabel>CPF</FormLabel>
							<FormInput
								type='text'
								id='cpf'
								name='cpf'
								value={formValues.cpf}
								error={errors.cpf}
								onChange={handleCpf}
								placeholder='000.000.000-00'
							/>
							{errors.cpf && <InputError fontSize='12px/18px'>Cpf inválido</InputError>}
						</FormFieldWrapper>
						<FlexFieldWrapper>
							<FormFieldWrapper halfField>
								<FormLabel>Telefone</FormLabel>
								<FormInput
									type='text'
									id='phone'
									name='phone'
									value={formValues.phone}
									onChange={handlePhone}
									placeholder='(00) 00000-0000'
									error={errors.phone}
									halfField
								/>
								{errors.phone && <InputError fontSize='12px/18px'>Telefone inválido</InputError>}
							</FormFieldWrapper>
							<FormFieldWrapper halfField second>
								<FormLabel>Data de nascimento</FormLabel>
								<FormInput
									type='text'
									id='birthDate'
									name='birthDate'
									value={formValues.birthDate}
									onChange={handleBirthdate}
									placeholder='00/00/0000'
									error={errors.birthDate}
									halfField
								/>
								{errors.birthDate && <InputError fontSize='12px/18px'>Data inválida</InputError>}
							</FormFieldWrapper>
						</FlexFieldWrapper>
						<FormFieldWrapper>
							<FormLabel>Número da Carteirinha</FormLabel>
							<FormInput
								type='text'
								id='cardNumber'
								name='cardNumber'
								value={formValues.cardNumber}
								onChange={handleCardNumber}
								error={errors.cardNumber}
								placeholder='Digite aqui o número da sua carteirinha'
								maxLength='20'
							/>
							{errors.cardNumber && <InputError>Número da Carteirinha inválida</InputError>}
						</FormFieldWrapper>
						<FormFieldWrapper>
							<ContainerCheckbox>
								<Checkbox
									id='checkbox-aggreement-term'
									onChange={handleAgreeConsent}
									checked={formValues.agreeConsent}
								/>
								<ConsentText>
									Aceito os{' '}
									<span>
										<a
											href='https://cdn.cosmicjs.com/62ab29d0-b4bb-11ea-b4fa-f3dcd232bf73-TERMO-DE-CONSENTIMENTOPACIENTE.pdf'
											rel='noopener noreferrer'
											target='_blank'
										>
											<strong>Termos de Consentimento</strong>
										</a>
									</span>{' '}
									da Bradesco Saúde.
								</ConsentText>
							</ContainerCheckbox>
						</FormFieldWrapper>
						<FormFieldWrapper>
							<ContainerCheckbox>
								<Checkbox
									id='checkbox-use-term'
									onChange={handleAgreeUse}
									checked={formValues.agreeUse}
								/>
								<ConsentText>
									Aceito os
									<span>
										<a
											href='https://cdn.cosmicjs.com/2873c060-b4bb-11ea-b4fa-f3dcd232bf73-TERMO-DE-USO-PACIENTES.pdf'
											rel='noopener noreferrer'
											target='_blank'
										>
											<strong> Termos de Uso </strong>
										</a>
									</span>
									da Bradesco Saúde.
								</ConsentText>
							</ContainerCheckbox>
						</FormFieldWrapper>
						<SubmitButton disabled={formIsWrong} onClick={submitForm}>
							Confirmar
						</SubmitButton>
					</Form>
				</FormWrapper>
			</ComponentWrapper>
		</>
	)
}

export default PatientRegister
