export const HOME = '/app'
export const PATIENT_DETAIL = HOME + '/patientDetail'
export const ASSISTANT = HOME + '/assistant'
export const NEW_PASSWORD = '/newPassword'
export const NEW_PATIENT = '/newPatient'
export const NEW_ASSISTANT = '/newAssistant'
export const PATIENT_PRE_CONSULTING = '/preConsulting'
export const TOUR = HOME + '/tour'
export const TOUR_PATIENT_DETAIL = HOME + '/tour/patientDetail'
export const FINANCIAL = HOME + '/financial'
export const FINANCIAL_REGISTER = HOME + '/financial/register'
export const NEW_PAYMENTPATIENT = '/checkout'
