import api from './api'
import { endpointsCreator } from './endpoints'

interface IPatientCreate {
	name: string
	cpf: string
	birthDate: string
	phone: string
	email: string
	accessToken: string
	uf?: string
	cardNumber?: string
	address?: {
		city: string
		complement: string
		neighborhood: string
		number: string
		postalCode: string
		state: string
		street: string
	}
}
const callNewPatient = async ({
	name,
	cpf,
	birthDate,
	phone,
	email,
	accessToken,
	uf,
	cardNumber,
	address
}: IPatientCreate) => {
	try {
		const newBirthDay = `${birthDate.substring(6, 10)}-${birthDate.substring(
			3,
			5
		)}-${birthDate.substring(0, 2)}T00:00:00.000Z`
		const others: any = {}
		if (uf) others.uf = uf
		if (cardNumber) others.cardNumber = cardNumber

		const addressBody = address
			? { address: { ...address, postalCode: address?.postalCode.replace(/\D/g, '') } }
			: {}

		const response = await api.put(`/appointments/${accessToken}/patients`, {
			name,
			cpf: cpf
				.replace('.', '')
				.replace('.', '')
				.replace('-', ''),
			birthDay: newBirthDay,
			phone: phone.replace(/\D/g, ''),
			email,
			optins: ['consent', 'use'],
			...addressBody,
			...others
		})

		return response
	} catch (err) {
		return err.response
	}
}

export const update = async ({ optins, uf, id, values }: any) => {
	try {
		const endpoint = endpointsCreator()
		const response = await api.put(endpoint.PATIENT.PUT_PATIENT, {
			id,
			uf,
			optins: [...optins, 'CONTATO_POS_CONSULTA'],
			...values
		})

		return response
	} catch (err) {
		return err.response
	}
}

export const putPatient = async (values: any, token: string) => {
	try {
		const endpoint = endpointsCreator()
		const response = await api.put(
			endpoint.PATIENT.PUT_PATIENT,
			{
				...values,
				optins: values.optins.map((optin: any) => optin.value)
			},
			{
				headers: {
					Authorization: token
				}
			}
		)

		return response
	} catch (err) {
		return err.response
	}
}

export const getPatient = async (patientId: string, token?: string) => {
	try {
		const endpoint = endpointsCreator(patientId)
		const response = await api.get(endpoint.PATIENT.GET_PATIENT_BY_ID, {
			headers: {
				Authorization: token
			}
		})
		return response
	} catch (err) {
		return err.response
	}
}

export default callNewPatient
