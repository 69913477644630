import styled, { css } from 'styled-components'

interface ICheckbox {
	checked: boolean
	disabled?: boolean
	colors?: { background: string; icon: string }
}

// export const CheckboxContainer = styled.div<ICheckbox>`
// 	display: flex;
// 	align-items: center;
// 	> input {
// 		opacity: 0;
// 		align-self: flex-start;
// 		margin-left: -12px;
// 	}
// 	> input + label {
// 		position: relative;
// 		padding-left: 25px;
// 		color: ${props => (props.disabled ? '#999' : '#333')};
// 		cursor: pointer;
// 		&:before {
// 			content: '';
// 			position: absolute;
// 			left: 0;
// 			top: -3px;
// 			width: 17px;
// 			height: 17px;
// 			border: 1px solid ${props => (props.disabled ? '#f2f2f2' : '#ccc')};
// 			background: ${props => (props.disabled ? '#f2f2f2' : 'transparent')};
// 			border-radius: 3px;
// 			${props =>
// 				props.checked &&
// 				css`
// 					background: ${props => (props.colors ? props.colors.background : '#f0f4f5')};
// 					border: 1px solid ${props => (props.colors ? props.colors.background : '#f0f4f5')};
// 				`}
// 		}

// 		&:after {
// 			content: '✓';
// 			position: absolute;
// 			top: 0px;
// 			left: 3px;
// 			font-size: 16px;
// 			color: ${props =>
// 				props.disabled ? 'transparent' : props.colors ? props.colors.icon : '#748a96'};
// 			transition: all 0.2s;
// 		}
// 	}
// 	/* > input:not(:checked) + label {
// 		&:after {
// 			opacity: 0;
// 			transform: scale(0);
// 		}
// 	}
// 	> input:disabled:not(:checked) + label {
// 		&:before {
// 			box-shadow: none;
// 			border-color: #bbb;
// 			background-color: #ddd;
// 		}
// 	}
// 	> input:checked + label {
// 		&:after {
// 			opacity: 1;
// 			transform: scale(1);
// 		}
// 	}
// 	> input:disabled:checked + label {
// 		&:after {
// 			color: #999;
// 		}
// 	}
// 	> input:disabled + label {
// 		color: #aaa;
// 	}
// 	> input:checked:focus + label,
// 	input:not(:checked):focus + label {
// 		&:before {
// 			border: 1px dotted blue;
// 		}
// 	} */
// `

export const CheckboxContainer = styled.label<ICheckbox>`
	display: block;
	position: relative;
	padding-left: 25px;
	// margin: 10px 0 0 0;
	cursor: pointer;
	font-size: 12px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	line-height: 1.4;

	// &:hover input ~ .checkSpan {
	// 	border: 1px solid ${props => props.theme.colors.font_highlight}74;
	// 	box-shadow: 0px 0px 6px ${props => props.theme.colors.font_highlight}74;
	// }
`
