import React from 'react'

import useState from '../../hooks/useState'

import { CheckboxContainer } from './styles'

interface IProps extends React.InputHTMLAttributes {
	label?: string
	disabled?: boolean
	colors?: { background: string; icon: string }
	action?: (param: boolean) => void
	// onChange?: (event: React.ChangeEvent<HTMLInputElement> | Record<string, any>) => void
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Checkbox: React.FC<IProps> = ({ label, action, disabled, ...props }) => {
	const [state, handleState] = useState({
		checked: false
	})

	const handleChange = e => {
		const { onChange } = props
		if (onChange) onChange(e)
		if (action) action(!state.checked)

		handleState({ checked: e.target?.checked })
	}

	return (
		<CheckboxContainer>
			<input
				disabled={disabled}
				id='checkbox-medical-item'
				type='checkbox'
				onChange={handleChange}
				{...props}
			/>
			<span className='checkSpan' />
			{label}
		</CheckboxContainer>
	)
}

export default Checkbox
