import styled from 'styled-components'
import {
	Button as SuperButton,
	InputError as SuperInputError,
	Span as SuperSpan,
	SubmitButtonRed as SuperSubmitButtonRed
} from '../superSetCommons'

interface Props {
	isButtonInvalid?: boolean
	halfField?: boolean
	second?: boolean
	error?: boolean
}

export const InputError = styled(SuperInputError)``

export const ComponentWrapper = styled.div`
	background: #f7f7f7;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
`

export const FormWrapper = styled.div`
	padding: 40px;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 0px 16px #00000019;
	border: 1px solid #e9e9e9;
	border-radius: 10px;

	@media (max-width: 767.98px) {
		padding: 20px 0;
	}
`

export const FormFieldWrapper = styled.div<Props>`
	margin: ${props => (props.second ? '0 0 0 28px' : props.halfField ? '0' : '14px auto 0')};
	width: ${props => (props.halfField ? '48%' : '100%')};
	// max-width: 337px;
	display: ${props => (props.halfField ? 'inline-block' : 'block')};
`

export const FlexFieldWrapper = styled(FormFieldWrapper)`
	display: flex;
`

export const FormLabel = styled.label`
	font: 14/32px CentraleSansRegular;
	letter-spacing: 0;
	color: ${props => props.theme.colors.font_dark};
	display: block;
	margin: 0 0 8px;
`

export const FormInput = styled.input<Props>`
	display: block;
	width: ${props => (props.halfField ? '100%' : '100%')};
	height: 48px;
	outline: none;
	border: solid 1px ${props => (props.error ? '#FF0000' : '#CCCCCC')};
	padding: 0 16px;
`

export const Form = styled.div`
	margin: auto;
`

export const Logo = styled.img`
	display: block;
	width: 350px;
	padding: 20px 0 20px 0;
	margin: 0 auto;
	max-width: 350px;
`

export const ContainerCheckbox = styled.label`
	display: flex;
	// position: relative;
	// padding-left: 35px;
	margin: 15px 0 0 0;
	cursor: pointer;
	font-size: 12px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	line-height: 1.4;

	&:hover input ~ .checkSpan {
		border: 1px solid ${props => props.theme.colors.font_highlight}74;
		box-shadow: 0px 0px 6px ${props => props.theme.colors.font_highlight}74;
	}

	// input[type='checkbox'] {
	// 	position: absolute;
	// 	opacity: 0;
	// 	cursor: pointer;
	// 	height: 0;
	// 	width: 0;
	// }

	// .checkSpan {
	// 	position: absolute;
	// 	top: -2px;
	// 	left: 0;
	// 	height: 20px;
	// 	width: 20px;
	// 	background-color: ${props => props.theme.colors.transparent};
	// 	border: 1px solid ${props => props.theme.colors.button_disabled};
	// 	box-shadow: 0px 0px 6px ${props => props.theme.colors.button_disabled_border};
	// 	border-radius: 2px;
	// }

	// input:checked ~ .checkSpan {
	// 	// background-color: ${props => props.theme.colors.red_alert};
	// 	border: 1px solid ${props => props.theme.colors.font_highlight};
	// }

	// /* Create the checkmark/indicator (hidden when not checked) */
	// .checkSpan:after {
	// 	content: '';
	// 	position: absolute;
	// 	display: none;
	// }

	// /* Show the checkmark when checked */
	// input:checked ~ .checkSpan:after {
	// 	display: block;
	// }

	// /* Style the checkmark/indicator */
	// .checkSpan:after {
	// 	left: 6px;
	// 	top: -1px;
	// 	width: 5px;
	// 	height: 13px;
	// 	border: 1px solid ${props => props.theme.colors.font_highlight};
	// 	box-shadow: 0px 0px ${props => props.theme.colors.font_highlight}74;
	// 	border-width: 0 3px 3px 0;
	// 	-webkit-transform: rotate(45deg);
	// 	-ms-transform: rotate(45deg);
	// 	transform: rotate(45deg);
	// }
`

export const ConsentText = styled.p`
	text-align: left;
	font: 14px/20px CentraleSansRegular;
	letter-spacing: 0;

	a {
		text-align: left;
		text-decoration: underline;
		font: Bold 14px/20px CentraleSans-Bold;
		letter-spacing: 0;
		color: ${props => props.theme.colors.font_highlight};
	}
`

export const SubmitButton = styled(SuperSubmitButtonRed)``

export const WelcomeText = styled(SuperSpan)`
	display: block;
	margin: 0px;
	text-align: left;
	// font: 16px/32px CentraleSansRegular;
	letter-spacing: 0;
	color: ${props => props.theme.colors.font_dark};
`
