import moment from 'moment'

export const months = [
	'janeiro',
	'fevereiro',
	'março',
	'abril',
	'maio',
	'junho',
	'julho',
	'agosto',
	'setembro',
	'outubro',
	'novembro',
	'dezembro'
]

export const days = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado']

export const isToday = (d: string) => {
	const formatedDate = `${d.substring(3, 5)}/${d.substring(0, 2)}/${d.substring(6, 10)}`
	return (
		new Date(formatedDate).toLocaleDateString('pt-br') === new Date().toLocaleDateString('pt-br')
	)
}

export const formatDate = (d: string) => {
	try {
		const day = d.substring(0, 2)
		const month = months[parseInt(d.substring(3, 5)) - 1]
		const year = d.substring(6, 10)
		return `${day} de ${month} de ${year}`
	} catch {
		return '-'
	}
}

export const isoStringToDateString = (str: string) => {
	try {
		if (!str?.includes(':')) throw new Error('')
		const parsedDate = new Date(str.replace('Z', ''))
		const pad = '00'
		const dayStr = parsedDate.getDate().toString()
		const day = pad.substring(0, pad.length - dayStr.length) + dayStr.toString()
		const monthStr = (parsedDate.getMonth() + 1).toString()
		const month = pad.substring(0, pad.length - monthStr.length) + monthStr.toString()
		const year = parsedDate.getFullYear()
		return `${day}/${month}/${year}`
	} catch {
		return str
	}
}

export const isoStringToHourString = (str: string) => {
	const parsedDate = new Date(str)
	return parsedDate.toTimeString().substring(0, 5)
}

export const dateToISO = (str: string) =>
	`${str.substring(6, 10)}-${str.substring(3, 5)}-${str.substring(0, 2)}T00:00:00.000Z`

// date: DD/MM/YYYY time HH:MM
export const dateToISOWithTime = (date: string, time: string) => {
	// TODO checar por regex se os args estão de acordo
	// return algum erro

	const d = new Date(
		parseInt(date.substring(6, 10)),
		parseInt(date.substring(3, 5)) - 1,
		date.substring(0, 2),
		parseInt(time.substring(0, 2)),
		parseInt(time.substring(3, 5)),
		0,
		0
	)

	return d.toISOString()
}

export const maskDate = (value: string) => {
	if (value && value.length <= 10) {
		const masked = value
			.toString()
			.replace(/\D/g, '')
			.slice(0, 10)
		if (masked.length >= 5) {
			return `${masked.slice(0, 2)}/${masked.slice(2, 4)}/${masked.slice(4)}`
		}
		if (masked.length >= 3) {
			return `${masked.slice(0, 2)}/${masked.slice(2)}`
		}
		return masked
	}
	return value.slice(0, 10)
}

export const validateDateNewConsulting = (value: string) => {
	if (value.length < 10) return false
	const date = moment(value, 'DD/MM/YYYY')

	return date.isValid && date >= moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY')
}

export const validateDateBirthday = (value: string) => {
	if (value.length < 10) return false
	const date = moment(value, 'DD/MM/YYYY')

	return date.year() > 1900 && date.year() <= moment().year()
}

export const isoToDateHour = (str: string) => {
	const parsedDate = new Date(str)
	return `${parsedDate.toLocaleDateString('pt-br')} ${parsedDate.toTimeString().substring(0, 5)}`
}

export const orderDate = (arrDate: any[], order?: 'asc' | 'desc') =>
	arrDate.sort((a: any, b: any) => {
		const dateA = new Date(a.date)
		const dateB = new Date(b.date)
		if (order === 'asc') return dateA < dateB ? -1 : dateA > dateB ? 1 : 0
		return dateA > dateB ? -1 : dateA < dateB ? 1 : 0
	})
