import SEO from '@cuidardigital/commons/components/Seo/seo'
import React from 'react'
import PageNewPatient from '../../components/NewPatient/'

export default (props: any) => (
	<>
		<SEO />
		<PageNewPatient {...props} />
	</>
)
